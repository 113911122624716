@import './abstracts/colors';
@import './abstracts/shadows';

.header-wrapper {
  width: 500px;
  border-left: solid 2px $faded-blue;
  border-right: solid 2px $faded-blue;
  border-bottom: solid 2px $faded-blue;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0px 3px 10px black;
  transition: ease-in-out 300ms;
}

@media screen and (min-width: 540px) {
  .header-wrapper {
    animation: slide-in-header ease-in-out 1200ms forwards;

    &:hover {
      background-color: $deep-blue;
    }
  }

  @keyframes slide-in-header {
    0% {
      transform: translateX(-100vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }
}


.header > a {
  text-decoration: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  & > p {
    font-size: 1.5em;
    font-family: 'Exo', sans-serif;
    color: $main-blue;
    filter: $blue-shadow;
  }
}

.app-logo {
  margin-top: 40px;
  height: 10vmin;
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 4s linear;
  }

}

@media screen and (max-width: 540px) {
  .app-logo {
    height: 15vmin;
  }

  .header-wrapper {
    max-width: 95vw;
  }

  .header > a {
    font-size: 1.15em;
  }

  .weather, .local-mach-1 {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
