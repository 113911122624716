@import './abstracts/colors';

.app {
  min-height: 100vh;
  min-width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
  color: $main-blue;
  background-color: $main-gray;
  overflow: hidden;
}

.modal-active {
  animation: blur 300ms forwards;
}

@keyframes blur {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(4px);
  }
}

.modal-inactive {
  animation: no-blur 300ms forwards;
}

@keyframes no-blur {
  0% {
    filter: blur(4px);
  }
  100% {
    filter: blur(0px);
  }
}
