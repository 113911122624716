@import './abstracts/colors';
@import './abstracts/shadows';

.outputs {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  border-top: solid 2px $main-magenta;
  border-bottom: solid 2px $main-magenta;
  width: 100vw;
  margin: 15px 0px;
  box-shadow: 0px 3px 10px black;
  transition: 300ms;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  &:hover {
    background-color: $deep-magenta;
  }
}

.weather, .local-mach-1 {
  min-height: 1em;

  & > p {
    color: $main-magenta;
    font-size: 16px;
    font-weight: 500;
    filter: drop-shadow(0px 0px 3px black) drop-shadow(0px 0px 0.5px $main-magenta);
    animation: appear 500ms ease-in-out forwards;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 540px) {
  .weather > p, .local-mach-1 > p {
    font-size: 12px;
  }
}

@media screen and (min-width: 540px) {
  .outputs {
    animation: outputsOpacity 1600ms ease-in forwards;
  }

  @keyframes outputsOpacity {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.output-value {
  color: $main-magenta;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 8px;
  filter: $magenta-shadow;
}

.outputs > * > p {
  margin: 0;
}
